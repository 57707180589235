@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  background-color: #f3f3f3;
  margin: 0;
  height: 100vh;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.blurred-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.code-highlight {
  counter-reset: linenumber;
}

.code-line::before {
  position: absolute;
  translate: -150% 0;
  content: counter(linenumber);
  counter-increment: linenumber;
  color: #f3f4f6;
  text-align: right;
  user-select: none;
  padding: 0 4px;
  min-width: 25px;
}
